import kabab from './components/meatball.png';
import pargit from './components/chicken-wings.png';
import React from "react";

const dishes = {
    kabab,
    pargit
}

const DishIcon = ({type, size = 50}) => <img src={dishes[type]} alt={dishes[type]} style={{maxWidth: size, maxHeight: size}} />;

export default DishIcon
