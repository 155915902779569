import {useLocalStorage, USERNAME_STORAGE_KEY} from "./utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {
    useHistory,
    useLocation,
} from "react-router-dom";


export function LoginPage() {
    const [savedUserName, setSavedUserName] = useLocalStorage(USERNAME_STORAGE_KEY, "")
    const [userName, setUserName] = useState("")


    let history = useHistory();
    let location = useLocation();
    const {from} = location.state || {from: {pathname: "/"}};
    let login = (user) => {
        if (user.length >= 3) {
            setSavedUserName(user);
            history.replace(from);
        }
    };

    return <div className="App-header">
        {
            !savedUserName
                ? <h2 style={{fontStyle: "italic"}}>
                    <div>Wait,</div>
                    <div style={{paddingLeft: 20}}>who are you again?!</div>
                </h2>
                : <h2 style={{fontStyle: "italic"}}>
                    <div>Not <span style={{color: "#e91e63"}}>{savedUserName}</span>?</div>
                    <div style={{paddingLeft: 20}}>who are you again?</div>
                </h2>
        }
        <div>
            <FormControl>
                <InputLabel htmlFor="standard-adornment-password">My name is...</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type="text"
                    value={userName}
                    onChange={event => setUserName(event.target.value)}
                    autoComplete='off'
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={() => login(userName)}>
                                <FontAwesomeIcon size={"xs"} fixedWidth icon={faCheck}/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>

        </div>
    </div>;
}