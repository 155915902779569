import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import DishChooserPage from "./DishChooserPage";
import {LoginPage} from "./LoginPage";
import {OrderPage} from "./OrderPage";
import {urls, useLocalStorage, USERNAME_STORAGE_KEY} from "./utils";


export default function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path={urls.login}>
                        <LoginPage/>
                    </Route>
                    <PrivateRoute path={urls.summary}>
                        <OrderPage/>
                    </PrivateRoute>
                    <PrivateRoute path={urls.order}>
                        <DishChooserPage/>
                    </PrivateRoute>
                    <Route render={() => <Redirect to={urls.order}/>}>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

const PrivateRoute = ({children, ...rest}) => {
    const [user] = useLocalStorage(USERNAME_STORAGE_KEY, "");
    return (
        <Route
            {...rest}
            render={({location}) =>
                user !== '' ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: urls.login,
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

