import {urls, useLocalStorage, USERNAME_STORAGE_KEY} from "./utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import {getVisualOrder} from "./DishChooserPage";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import * as api from "./api";
import Fab from "@material-ui/core/Fab";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import DishIcon from "./DishIcon";

function attendeesComparator(user) {
    return (a, b) => {
        if (a.name === user) {
            return -1;
        }
        if (b.name === user) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    };
}

export function OrderPage() {
    const PULLING_DURATION = 2000;
    const [user] = useLocalStorage(USERNAME_STORAGE_KEY, "")
    const [order, setOrder] = useState([])

    // initial loading
    useEffect(() => {
        api.getOrder().then(r => setOrder(r))
    }, []);

    // periodic update of order state
    useEffect(() => {
        const intervalId = setInterval(() => {
            api.getOrder().then(r => setOrder(r))
        }, PULLING_DURATION);
        return () => clearInterval(intervalId);
    }, []);

    const removeOrder = useCallback(async () => {
        await api.withdrawOrder(user);
        const updated = await api.getOrder();
        setOrder([...updated]);
    }, [user])

    const isUserOrdered = useMemo(() => order
            .map(i => i.name)
            .includes(user),
        [order, user]);

    const history = useHistory();


    return <div className="App-header">
        <h1 className="mainTitle">
            Current Order
        </h1>
        <div >
            <span style={{marginRight: 20, display: "inline-flex", alignItems: "center", gap: 10, fontSize: '2em'}}>
                {order.map(i => i.pargit).reduce((a, b) => a + b, 0)} <DishIcon type="pargit" size={50} />
            </span>
            <span style={{display: "inline-flex", alignItems: "center", gap: 10, fontSize: '2em'}}>
                {order.map(i => i.kabab).reduce((a, b) => a + b, 0)} <DishIcon type="kabab" size={50} />
            </span>
        </div>
        <h3>Attendance</h3>
        <div className="attendenceList">
            {
                order
                    .sort(attendeesComparator(user))
                    .map(
                        (member) => <div key={member.name} style={{color: member.name === user ? "#e91e63" : "white", display: "flex", alignItems: "center"}}>
                            <span style={{paddingRight: 15}}>{getVisualOrder(member.kabab, {size: 30})}</span>
                            <span style={{paddingBottom: 8}}>{member.name}</span>
                        </div>)
            }
        </div>
        <Fab className="mainFab" color="primary" aria-label="add"
             onClick={() => history.push(urls.order)}>
            <FontAwesomeIcon size="2x" icon={isUserOrdered ? faPencilAlt : faPlus}/>
        </Fab>

        <div className="bottomBar">
            <Button style={{marginTop: 20, marginRight: 20}} variant="text" color="secondary"
                    onClick={() => history.push(urls.login)}>
                <small>Not <strong>{user}</strong>?</small>
            </Button>

            {
                isUserOrdered && <Button style={{marginTop: 20}} variant="text" color="secondary"
                                         onClick={removeOrder}>
                    <small>Leave Order</small>
                </Button>
            }
        </div>
    </div>;
}