import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'typeface-nunito';
import App from "./App";
import {MuiThemeProvider} from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import pink from "@material-ui/core/colors/pink";
import blue from "@material-ui/core/colors/blue";


const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: pink[500],
        },
        secondary: {
            main: blue[500],
        },

    },
});


ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
            <App/>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
