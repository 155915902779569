import React, {useCallback, useState} from 'react';
import './DishChooserPage.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileSignature} from '@fortawesome/free-solid-svg-icons'
import Slider from "@material-ui/core/Slider";
import TextTransition from "react-text-transition";
import Button from "@material-ui/core/Button";
import {urls, useLocalStorage, USERNAME_STORAGE_KEY} from "./utils";
import * as api from "./api";
import {useHistory} from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import DishIcon from "./DishIcon";

const dishNames = {
    0: "Healthy",
    1: "Safe",
    2: "Bold"
}

export const getVisualOrder = (kababs, {size = undefined} = {}) => {
    const res = [];
    for (let i = 0; i < 2; i++) {
        res.push(<DishIcon key={i} size={size} type={i < kababs ? "kabab" : "pargit"} />)
    }
    return res;
}

export default function DishChooserPage() {
    const [kebabs, setKebabs] = useState(1);
    const createResultView = useCallback(({size = 70} = {}) => getVisualOrder(kebabs, {size}), [kebabs]);
    const [user] = useLocalStorage(USERNAME_STORAGE_KEY, "");
    const history = useHistory();

    const makeOrder = useCallback(() => {
        api.makeOrder(user, kebabs)
            .then(() => {
                history.push(urls.summary)
            })
    }, [user, kebabs, history])

    return (
        <div className="App">
            <header className="App-header">
                <div className="mainTitle">
                    <span style={{fontWeight: 700}}>Do you feel like going... </span>
                    <TextTransition
                        inline
                        style={{fontSize: "2em", fontWeight: 900}}
                        text={dishNames[kebabs] + "?"}
                    />
                </div>
                <p style={{margin: 70}}>
                    {createResultView()}
                </p>
                <Slider
                    style={{width: 200}}
                    defaultValue={1}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="off"
                    marks
                    track={false}
                    color={"primary"}
                    min={0}
                    max={2}
                    onChange={((event, value) => {
                        setKebabs(value);
                        window.navigator.vibrate(50);
                    })}
                />

                <div className="bottomBar">
                    <Button style={{marginTop: 20, marginRight: 20}} variant="text" color="secondary"
                            onClick={() => history.push(urls.login)}>
                        <small>Not <strong>{user}</strong>?</small>
                    </Button>

                    <Button style={{marginTop: 20}} variant="text" color="secondary"
                            onClick={() => history.push(urls.summary)}>
                        <small>See order</small>
                    </Button>
                </div>

                <Fab className="mainFab" color="primary" aria-label="add"
                     onClick={makeOrder}>
                    <FontAwesomeIcon size="2x" icon={faFileSignature}/>
                </Fab>
            </header>
        </div>
    );
}

